<template>
  <div class="paymentInfo">
    <van-cell-group v-if="orderNumber">
      <van-cell
        class="step-cell"
        :title="$t.orderDetails.orderNumber"
        :value="orderNumber"
      />
    </van-cell-group>

    <van-cell-group v-if="payAllPrice">
      <van-cell
        class="step-cell"
        :title="$t.orderDetails.totalPrice"
        :value="payAllPrice"
      />
    </van-cell-group>

    <van-steps
      :active="active"
      :active-icon="activeIcon"
      :active-color="activeColor"
    >
      <van-step>{{ $t.paymentInfo.upload }}</van-step>
      <van-step>{{ $t.paymentInfo.verifying }}</van-step>
      <van-step>{{ $t.paymentInfo.verifyFailed }}</van-step>
      <van-step>{{ $t.paymentInfo.verifySuccess }}</van-step>
    </van-steps>

    <van-cell-group v-if="failureReason">
      <van-cell
        class="step-cell"
        :title="$t.paymentInfo.reason"
        :value="failureReason"
        v-if="failureReason"
      />
    </van-cell-group>

    <div class="cellMain" v-if="step == 1">
      <h3 class="cellTitle">
        <span class="infoLabel">1.</span>
        {{ $t.paymentInfo.verifyInfo }}
      </h3>
      <van-cell-group v-if="bankInfo">
        <van-cell
          class="btnCell"
          :title="$t.paymentInfo.accountName"
          :value="bankInfo.AccountName"
        />
        <van-cell
          class="btnCell"
          :title="$t.paymentInfo.accountNumber"
          :value="bankInfo.AccountNumber"
        />
        <van-cell
          class="btnCell"
          :title="$t.paymentInfo.bankAddress"
          :value="bankInfo.BankAddress"
        />
        <van-cell
          class="btnCell"
          :title="$t.paymentInfo.bankName"
          :value="bankInfo.BankName"
        />
        <van-cell
          class="btnCell"
          :title="$t.paymentInfo.swiftCode"
          :value="bankInfo.SwiftCode"
        />
      </van-cell-group>
    </div>

    <div class="formMain" v-if="step == 2">
      <!-- 银行卡正面 -->
      <div :class="['formBox', !isEdit ? 'BoxLock' : '']">
        <h3 class="formTitle" v-if="active != 3">
          <span class="infoLabel">2.</span>
          {{ $t.paymentInfo.title }}
        </h3>

        <!-- 手机端 -->
        <div v-if="platform == 'android' && isApp">
          <van-row
            class="formImgBox"
            type="flex"
            align="center"
            v-if="paymentInfo.screenshot.length > 0"
          >
            <van-col
              span="12"
              v-for="(item, index) in paymentInfo.screenshot"
              :key="index"
              @click="setUploadImg(index)"
            >
              <van-image :src="item && imgUrlFilter(item)" class="logoIcon">
                <template v-slot:error>
                  <img
                    class="ImgIcon"
                    src="~assets/img/auth/icone_c_authentication_photo@2x.png"
                  />
                </template>
              </van-image>
            </van-col>
            <van-col
              span="12"
              v-if="paymentInfo.screenshot.length == 1 && isEdit"
              @click="setUploadImg(1)"
            >
              <img
                class="logoIcon"
                src="~assets/img/auth/icone_c_authentication_photo@2x.png"
              />
            </van-col>
          </van-row>
          <van-row
            class="formImgBox"
            type="flex"
            align="center"
            v-else
            @click="setUploadImg(0)"
          >
            <van-col span="12">
              <img
                class="logoIcon"
                src="~assets/img/auth/icone_c_authentication_photo@2x.png"
              />
            </van-col>
            <van-col span="12">
              <img
                class="logoIcon"
                src="~assets/img/auth/icone_c_authentication_photo@2x.png"
              />
            </van-col>
          </van-row>
        </div>
        <!-- 网页端 -->
        <div v-else>
          <van-row
            class="formImgBox"
            type="flex"
            align="center"
            v-if="paymentInfo.screenshot.length > 0"
          >
            <div
              v-if="!isEdit"
              class="preview-container"
              @click="imagePreview(0)"
            ></div>
            <van-col
              span="12"
              v-for="(item, index) in paymentInfo.screenshot"
              :key="index"
            >
              <van-uploader
                :after-read="afterRead"
                :disabled="isEdit ? false : true"
              >
                <van-image :src="item && imgUrlFilter(item)" class="logoIcon">
                  <template v-slot:error>
                    <img
                      class="ImgIcon"
                      src="~assets/img/auth/icone_c_authentication_photo@2x.png"
                    />
                  </template>
                </van-image>
              </van-uploader>
            </van-col>
            <van-col
              span="12"
              v-if="paymentInfo.screenshot.length == 1 && isEdit"
            >
              <van-uploader :after-read="afterRead">
                <img
                  class="logoIcon"
                  src="~assets/img/auth/icone_c_authentication_photo@2x.png"
                />
              </van-uploader>
            </van-col>
          </van-row>
          <van-row v-else class="formImgBox">
            <van-uploader :after-read="afterRead">
              <van-col span="12">
                <img
                  class="logoIcon"
                  src="~assets/img/auth/icone_c_authentication_photo@2x.png"
                />
              </van-col>
              <van-col span="12">
                <img
                  class="logoIcon"
                  src="~assets/img/auth/icone_c_authentication_photo@2x.png"
                />
              </van-col>
            </van-uploader>
          </van-row>
        </div>
      </div>
      <!-- 表单信息 -->
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle"
          >{{ $t.paymentInfo.yourName }} ({{ $t.paymentInfo.required }})</label
        >
        <input
          class="inputInfo"
          v-model="paymentInfo.trueName"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.yourNamePlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle"
          >{{ $t.paymentInfo.bankName }} ({{ $t.paymentInfo.required }})</label
        >
        <input
          class="inputInfo"
          v-model="paymentInfo.bankName"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.bankNamePlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle"
          >{{ $t.paymentInfo.bankAccount }} ({{
            $t.paymentInfo.required
          }})</label
        >
        <input
          class="inputInfo"
          v-model="paymentInfo.bankNumber"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.bankAccountPlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle">{{ $t.paymentInfo.iBankNo }}</label>
        <input
          class="inputInfo"
          v-model="paymentInfo.iBankNo"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.iBankNoPlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle">{{ $t.paymentInfo.swiftCode }}</label>
        <input
          class="inputInfo"
          v-model="paymentInfo.swiftCode"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.swiftCodePlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle">{{ $t.paymentInfo.bankAddress }}</label>
        <input
          class="inputInfo"
          v-model="paymentInfo.bankAddress"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.bankAddressPlc"
        />
      </div>
      <div :class="['inputBox', !isEdit ? 'BoxLock' : '']">
        <label class="inputTitle">{{ $t.paymentInfo.yourPostAdd }}</label>
        <input
          class="inputInfo"
          v-model="paymentInfo.postAddress"
          type="text"
          :disabled="!isEdit"
          :placeholder="$t.paymentInfo.yourPostAddPlc"
        />
      </div>
    </div>
    <!-- 提交 -->
    <div class="footerBox" v-if="step == 1">
      <div class="sub" @click="step = 2">{{ $t.auth.next }}</div>
    </div>
    <div class="footerBox" v-if="step == 2 && isEdit">
      <div class="sub" @click="Submit" v-if="active == 2">
        {{ $t.paymentInfo.resubmit }}
      </div>
      <div class="sub" @click="Submit" v-else>{{ $t.paymentInfo.done }}</div>
    </div>
  </div>
</template>
<script>
import { isAPP, getPlatform } from "@/plugins/environment/type.js";
import Schema from "async-validator";
import Vue from "vue";
import {
  Image,
  Uploader,
  Dialog,
  Row,
  Col,
  Cell,
  CellGroup,
  Step,
  Steps,
} from "vant";
Vue.use(Uploader)
  .use(Image)
  .use(Row)
  .use(Col)
  .use(Cell)
  .use(CellGroup)
  .use(Step)
  .use(Steps);

export default {
  name: "Payment",
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
      step: 1,
      active: 0,
      activeIcon: "more-o",
      activeColor: "#25b9ff",
      cdid: "",
      userId: "",
      orderNumber: "",
      payAllPrice: "",
      isEdit: true,
      failureReason: "",
      currentSelect: 0,
      paymentInfo: {
        screenshot: [], // 银行卡
        trueName: "", // 姓名
        bankName: "", // 银行名称
        bankNumber: "", // 银行卡号
        iBankNo: "", //
        swiftCode: "", //
        bankAddress: "", // 银行地址
        postAddress: "", // 邮寄地址
      },
      bankInfo: {},
    };
  },
  mounted() {
    const { OrderNumber, IsEdit, PaymentData, PayAllPrice, CDId } =
      this.$route.query;
    this.setSteps(PaymentData);
    this.cdid = CDId;
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.isEdit = JSON.parse(IsEdit);
    this.step = this.isEdit ? 1 : 2;
    this.orderNumber = OrderNumber;
    this.payAllPrice = PayAllPrice;
    this.failureReason = PaymentData.FailureReason || "";
    this.paymentInfo.screenshot = PaymentData.PaymentScreenshots || [];
    //this.paymentInfo.screenshot = ["/UploadFiles/Vancheen/B_IMG/2021_11/202111161434150494.jpeg"];//测试用
    this.paymentInfo.trueName = PaymentData.TrueName || "";
    this.paymentInfo.bankName = PaymentData.BankName || "";
    this.paymentInfo.bankNumber = PaymentData.BankNumber || "";
    this.paymentInfo.iBankNo = PaymentData.IBankNo || "";
    this.paymentInfo.swiftCode = PaymentData.SwiftCode || "";
    this.paymentInfo.bankAddress = PaymentData.BankAddress || "";
    this.paymentInfo.postAddress = PaymentData.PostAddress || "";
  },
  created() {
    window.openAlbum = this.openAlbum;
    this.getBankInfo();
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    setSteps(values) {
      var step = values.IsCheck;
      if (step == 0) {
        //审核中
        this.active = 1;
        this.activeIcon = "clock-o";
        this.activeColor = "#FFA500";
      }
      if (step == 1) {
        //审核通过
        this.active = 3;
        this.activeIcon = "passed";
        this.activeColor = "#48D58E";
      }
      if (step == 2) {
        //审核不通过
        this.active = 2;
        this.activeIcon = "close";
        this.activeColor = "#FF0000";
      }
    },
    getBankInfo() {
      this.$commonMethod.showLoading();
      this.$api.order
        .GetVanCheenBankAccountInfo()
        .then((res) => {
          const data = res.data;
          this.bankInfo = data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    Submit() {
      const rule = {
        screenshot: {
          required: true,
          message: this.$t.paymentInfo.title,
        },
        trueName: {
          required: true,
          message: this.$t.paymentInfo.yourNamePlc,
        },
        bankName: {
          required: true,
          message: this.$t.paymentInfo.bankNamePlc,
        },
        bankNumber: [
          { required: true, message: this.$t.paymentInfo.bankAccountPlc },
        ],
        iBankNo: {
          required: false,
          message: this.$t.paymentInfo.iBankNoPlc,
        },
        swiftCode: {
          required: false,
          message: this.$t.paymentInfo.swiftCodePlc,
        },
        bankAddress: {
          required: false,
          message: this.$t.paymentInfo.bankAddressPlc,
        },
        postAddress: {
          required: false,
          message: this.$t.paymentInfo.yourPostAddPlc,
        },
      };
      const validator = new Schema(rule);
      validator
        .validate(this.paymentInfo)
        .then((_) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
          let param = {
            ChannelDealerId: this.cdid,
            UserId: this.userId,
            OrderNumber: this.orderNumber,
            PaymentScreenshots: this.paymentInfo.screenshot,
            TrueName: this.paymentInfo.trueName,
            BankName: this.paymentInfo.bankName,
            BankNumber: this.paymentInfo.bankNumber,
            IBankNo: this.paymentInfo.iBankNo,
            SwiftCode: this.paymentInfo.swiftCode,
            BankAddress: this.paymentInfo.bankAddress,
            PostAddress: this.paymentInfo.postAddress,
          };
          return this.$api.order.AddOrderLinePaymentRecord(param);
        })
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          Dialog.confirm({
            message: "success",
            className: "showDialogClass",
          })
            .then(() => {
              this.$commonEnv.commonAction(this.$commonEnv.actionType.pop, {});
            })
            .catch(() => {
              this.$commonEnv.commonAction(this.$commonEnv.actionType.pop, {});
            });
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                titleMsg: err.errors[0].message,
                type: "danger",
              }
            );
          } else {
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.hideLoading
            );
            this.$commonEnv.commonAction(
              this.$commonEnv.actionType.showNotify,
              {
                type: "danger",
                titleMsg: err.message,
              }
            );
          }
        });
    },
    setUploadImg(index) {
      if (this.isEdit) {
        this.currentSelect = index;
        this.$commonEnv.commonAction(this.$commonEnv.actionType.openAlbum, {
          max: 2,
          callback: "openAlbum",
        });
      } else {
        this.imagePreview(index);
      }
    },
    imagePreview(index) {
      if (!this.isEdit) {
        this.$commonEnv.commonAction(
          this.$commonEnv.actionType.picturePreview,
          {
            pictureList: this.paymentInfo.screenshot,
            startPosition: index,
          }
        );
      }
    },
    openAlbum(data) {
      var file = JSON.parse(data);
      var files = file.DefaultUrl.split("|");
      var screenshot = this.paymentInfo.screenshot;
      this.paymentInfo.screenshot = [];
      if (files.length == 1) {
        screenshot[this.currentSelect] = files[0];
      } else {
        for (const index in files) {
          screenshot[index] = files[index];
        }
      }
      this.paymentInfo.screenshot = screenshot;
    },
    afterRead(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$commonMethod.showLoading();
      this.$api.system
        .uploadImgFile(formData)
        .then((res) => {
          if (this.paymentInfo.screenshot.length >= 2) {
            this.paymentInfo.screenshot.shift();
          }
          this.paymentInfo.screenshot.push(res.data.DefaultUrl);

          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin fontCommon {
  font-size: 1rem;
  color: #777777;
  font-family: Helvetica;
  font-weight: bold;
}
.van-hairline--top-bottom::after {
  border-width: 0px !important;
}
.step-cell {
}
.cellMain {
  margin: 5% 0%;
  background-color: #ffffff;
  .cellTitle {
    @include fontCommon;
    margin: 0;
    padding: 10px;
  }
}
.formMain {
  margin: 5% 0%;
  background-color: #ffffff;
}
.infoLabel {
  font-size: 1.5rem;
  color: #01aaa3;
}
.paymentInfo {
  position: relative;
  min-height: 100vh;
  .formBox {
    box-sizing: border-box;
    border-bottom: 0.1rem solid #ececec;
    padding-top: 10px;
    padding-bottom: 10px;
    ::v-deep .van-uploader {
      width: 100%;
    }
    ::v-deep .van-uploader__wrapper {
      justify-content: center;
    }
    ::v-deep .van-uploader__input-wrapper {
      width: 100%;
    }
    ::v-deep .van-uploader__wrapper--disabled {
      opacity: 1 !important;
    }
    .formTitle {
      @include fontCommon;
      margin: 0 0 10px 10px;
    }
    .formImgBox {
      width: 100%;
      height: 8rem;
      overflow: hidden;
      margin: 5% auto;
      text-align: center;
      
      .preview-container {
        position: absolute;
        width: 100%;
        height: 8rem;
        z-index: 11;
      }
      .logoIcon {
        width: 8rem;
        height: 8rem;
        position: relative;
        object-fit: contain;
        background: transparent;
        .ImgIcon {
          width: 8rem;
          height: 8rem;
          position: relative;
          object-fit: contain;
          background: transparent;
        }
        ::v-deep .van-image__img {
          width: 8rem;
          height: 8rem;
          position: relative;
          object-fit: contain;
          background: transparent;
        }
        ::v-deep .van-image__error {
          width: 8rem;
          height: 8rem;
          position: relative;
          object-fit: contain;
          background: transparent;
        }
      }
    }
  }
  .BoxLock {
    background-color: #eff0f5 !important;
  }
  .inputBox {
    padding: 0.8rem 0.8rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 0.1rem solid #eff0f5;
    .inputTitle {
      @include fontCommon;
    }
    .inputInfo {
      background: none;
      outline: none;
      border: none;
      flex: 1;
      text-align: right;
      @include fontCommon;
      &::placeholder {
        font-size: 12px;
        font-family: Helvetica;
        font-weight: 400;
        color: #cacaca;
        text-align: right;
      }
    }
  }
}
.footerBox {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  .sub {
    width: 80%;
    padding: 0.4rem 0;
    line-height: 2;
    border-radius: 3rem;
    background: linear-gradient(left, #71d283, #01aaa3);
    text-align: center;
    font-size: 1rem;
    margin: 0 auto;
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
